import { call, put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* steeringThetaPhi(response) {
  try {
    const [sn, { freq, module, channel }] = Object.entries(response?.data)[0]

    // port ex: 'port_1
    // module = [1]
    // v1 這版沒有 tile-up 所以直接裝 array 就好
    const port = `port_${module[0]}`

    const newCurrentData = yield call(getCurrentData, sn)
    newCurrentData.deviceControl[freq][port].channel = channel

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog(error)
  }
}
