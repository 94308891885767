import React from 'react'

import { container } from 'src/containers/operating/device/beamformers/style'

import RowPower from './RowPower'
import RowTheta from './RowTheta'
import RowPhi from './RowPhi'
import RowGain from './RowGain'
import RfMode from './RfMode'
import Mask from 'src/components/Mask'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { maskClass } from './MultiAiP'

const SingleDevice = props => {
  const { current } = useGetCurrentDeviceData()
  const singleBeamIndex = 3

  const isStandby =
    current.data?.deviceControl?.steering.separateBeamsData.find(
      e => e.beamIndex === singleBeamIndex
    )?.rfMode === 'standby'

  const isExternalControl =
    current.data?.deviceControl?.common?.isExternalControl

  return (
    <div className={container}>
      <div className={`bg-light-4/10 rounded-[4px] relative`}>
        {isExternalControl && (
          <Mask className={`${maskClass}˝ rounded-[0_0_4px_4px]`}>
            Not available in external control
          </Mask>
        )}

        <div className='p-4'>
          <RfMode beamIndex={singleBeamIndex} />
        </div>

        <div className='relative flex flex-col px-4 pb-4 gap-y-4'>
          {isStandby && (
            <Mask className={`${maskClass}˝ rounded-[0_0_4px_4px]`}>
              Enabled in TX or RX mode
            </Mask>
          )}
          <RowPower beamIndex={singleBeamIndex} />

          <RowTheta beamIndex={singleBeamIndex} />

          <RowPhi beamIndex={singleBeamIndex} />

          <RowGain beamIndex={singleBeamIndex} />
        </div>
      </div>
    </div>
  )
}

export default SingleDevice
