import { all, fork, take } from 'redux-saga/effects'

// action

import {
  cloverCellCTTC_common_refSignalSourceChange_watcher,
  cloverCellCTTC_common_frequencyChange_watcher,
  cloverCellCTTC_common_externalControlChange_watcher,
  cloverCellCTTC_steering_controlPatternChange_watcher,
  cloverCellCTTC_steering_beamSynthesisChange_watcher,
  cloverCellCTTC_steering_rfModeChange_watcher,
  cloverCellCTTC_steering_powerChange_watcher,
  cloverCellCTTC_steering_thetaPhiGainChange_watcher,
} from 'src/redux/actions/beamformers/cloverCellCTTCAction'

// handlers
import {
  common_refSignalSourceChange as handle_common_refSignalSourceChange,
  common_frequencyChange as handle_common_frequencyChange,
  common_externalControlChange as handle_common_externalControlChange,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/cttc/common'
import {
  steering_controlPatternChange as handle_steering_controlPatternChange,
  steering_beamSynthesisChange as handle_steering_beamSynthesisChange,
  steering_rfModeChange as handle_steering_rfModeChange,
  steering_powerChange as handle_steering_powerChange,
  steering_thetaPhiGainChange as handle_steering_thetaPhiGainChange,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/cttc/steering'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* common_refSignalSourceChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_common_refSignalSourceChange_watcher.type
    )
    devLog('[watcher] common_refSignalSourceChange', action.payload)
    yield fork(handle_common_refSignalSourceChange, action.payload)
  }
}
function* common_frequencyChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_common_frequencyChange_watcher.type
    )
    devLog('[watcher] common_frequencyChange', action.payload)
    yield fork(handle_common_frequencyChange, action.payload)
  }
}
function* common_externalControlChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_common_externalControlChange_watcher.type
    )
    devLog('[watcher] common_externalControlChange', action.payload)
    yield fork(handle_common_externalControlChange, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Steering      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* steering_controlPatternChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_steering_controlPatternChange_watcher.type
    )
    devLog('[watcher] steering_controlPatternChange', action.payload)
    yield fork(handle_steering_controlPatternChange, action.payload)
  }
}

function* steering_beamSynthesisChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_steering_beamSynthesisChange_watcher.type
    )
    devLog('[watcher] steering_beamSynthesisChange', action.payload)
    yield fork(handle_steering_beamSynthesisChange, action.payload)
  }
}
function* steering_rfModeChange() {
  while (true) {
    const action = yield take(cloverCellCTTC_steering_rfModeChange_watcher.type)
    devLog('[watcher] steering_rfModeChange', action.payload)
    yield fork(handle_steering_rfModeChange, action.payload)
  }
}
function* steering_powerChange() {
  while (true) {
    const action = yield take(cloverCellCTTC_steering_powerChange_watcher.type)
    devLog('[watcher] steering_powerChange', action.payload)
    yield fork(handle_steering_powerChange, action.payload)
  }
}
function* steering_thetaPhiGainChange() {
  while (true) {
    const action = yield take(
      cloverCellCTTC_steering_thetaPhiGainChange_watcher.type
    )
    devLog('[watcher] steering_thetaPhiGainChange', action.payload)
    yield fork(handle_steering_thetaPhiGainChange, action.payload)
  }
}

export default function* bboxWatchers() {
  yield all([
    common_refSignalSourceChange(),
    common_frequencyChange(),
    common_externalControlChange(),
    steering_controlPatternChange(),
    steering_beamSynthesisChange(),
    steering_rfModeChange(),
    steering_powerChange(),
    steering_thetaPhiGainChange(),
  ])
}
