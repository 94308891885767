import React from 'react'

import SegmentedButton from 'src/components/Button/SegmentedButton'
import Tooltip from 'src/components/Popup/Tooltip'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  internalOutputOptions,
  externalInputOptions,
} from 'src/constants/freqConverter'

import { HiLockClosed } from 'react-icons/hi'

const RefSourceFreq = props => {
  const { size, source, disabled, value, onChange } = props
  const { current } = useGetCurrentDeviceData()

  const deviceType = current.deviceType

  const lstSupportFreq = current.data.settings.device[source]?.lstFreqOptions
  const isItemDisabled = item => !lstSupportFreq.includes(item)

  const options = {
    internal: internalOutputOptions,
    external: externalInputOptions,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <SegmentedButton
      className=''
      size={size}
      type='outlined'
      disabled={disabled}
      value={value}
      onChange={onChange}>
      {options[source][deviceType].map(item => (
        <Tooltip
          key={'source freq item tooltip' + item}
          title={`${item / 1000} MHz is not
            supported in this model`}
          content='Contact us to learn more'
          placement='top'
          titleClassName='whitespace-pre-line'
          enableDismiss={true}
          disabled={!isItemDisabled(item)}>
          <SegmentedButton.Option
            name={item}
            className='w-full'
            disabled={isItemDisabled(item)}>
            {isItemDisabled(item) && <HiLockClosed className={lockIconClass} />}
            {item / 1000} MHz
          </SegmentedButton.Option>
        </Tooltip>
      ))}
    </SegmentedButton>
  )
}

export default RefSourceFreq

const lockIconClass = `inline text-[16px] -translate-y-[2px] mr-[6px]`
