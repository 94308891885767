import React from 'react'

import { container } from 'src/containers/operating/device/beamformers/style'

import ControlPattern from './ControlPattern'
import BeamSynthesis from './BeamSynthesis'
import RfMode from './RfMode'
import RowPower from './RowPower'
import RowTheta from './RowTheta'
import RowPhi from './RowPhi'
import RowGain from './RowGain'
import Mask from 'src/components/Mask'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const theListOfSortedBeamIndices = [3, 1, 4, 2]

const boardClass = `
          relative
          bg-light-4/10 
          rounded-[4px] 
          `
export const maskClass = `
          flex items-center justify-center 
          text-light-4 z-30
          select-none cursor-not-allowed
          `

const MultiAiP = props => {
  const { current } = useGetCurrentDeviceData()

  const controlPattern = current?.data?.deviceControl?.steering?.controlPattern
  const isBeamSynthesis =
    current?.data?.deviceControl?.steering?.isBeamSynthesis

  const isBeamSynthesisStandby =
    current.data?.deviceControl?.steering.synthesizedBeamData.rfMode ===
    'standby'

  const isSeparateBeamsStandby = beamIndex =>
    current.data?.deviceControl?.steering.separateBeamsData.find(
      e => e.beamIndex === beamIndex
    )?.rfMode === 'standby'

  const isExternalControl =
    current.data?.deviceControl?.common?.isExternalControl

  return (
    <div className={`${container} flex flex-col gap-y-4`}>
      {isExternalControl && (
        <Mask className={`${maskClass}˝ rounded-[0_0_4px_4px]`}>
          Not available in external control
        </Mask>
      )}

      <ControlPattern />
      <BeamSynthesis />

      {isBeamSynthesis && (
        <div className={boardClass}>
          <div className='p-4'>
            <RfMode beamIndex={null} />
          </div>

          <div className='relative flex flex-col px-4 pb-4 gap-y-4'>
            {isBeamSynthesisStandby && (
              <Mask className={`${maskClass}˝ rounded-[0_0_4px_4px]`}>
                Enabled in TX or RX mode
              </Mask>
            )}
            <RowPower beamIndex={null} />

            <RowTheta beamIndex={null} />

            <RowPhi beamIndex={null} />

            <RowGain beamIndex={null} />
          </div>
        </div>
      )}

      {!isBeamSynthesis && (
        <div className='grid grid-cols-2 gap-4'>
          {theListOfSortedBeamIndices.map((e, i) => (
            <div key={`aip ${e} ${i}`} className={boardClass}>
              {!controlPattern.includes(e) && (
                <Mask className={`${maskClass} rounded-[4px]`}>Power OFF</Mask>
              )}

              <div className='p-4'>
                <RfMode beamIndex={e} />
              </div>

              <div className='relative flex flex-col px-4 pb-4 gap-y-4'>
                {controlPattern.includes(e) && isSeparateBeamsStandby(e) && (
                  <Mask className={`${maskClass}˝ rounded-[0_0_4px_4px]`}>
                    Enabled in TX or RX mode
                  </Mask>
                )}
                <RowPower beamIndex={e} />

                <RowTheta beamIndex={e} />

                <RowPhi beamIndex={e} />

                <RowGain beamIndex={e} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MultiAiP
