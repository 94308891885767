import { call, put, delay } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import { modalActions } from 'src/redux/slices/modal'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* setRefSource(response) {
  try {
    const [sn, { refSource, inputRefSource, ledIndicators }] = Object.entries(
      response?.data
    )[0]

    let { currentData } = yield call(getCommonArgs, sn)
    let isFreqDisabled = false
    currentData.settings.device.refSource = refSource
    currentData.settings.device.inputRefSource.currentSelection = inputRefSource

    //! 要確認切回 internal 原本打開的 outputRefSource 是否要關掉

    const external = 1
    if (refSource === external) {
      // spec: inputRefSource = LO 的話 LO 不能 output
      if (inputRefSource === 'LO') {
        currentData.settings.device.isOutputLoEnable = false
        isFreqDisabled = true
      }

      // spec: 切到 external 的話要把 output ref source 關掉
      currentData.settings.device.outputRefSource.isEnable = false

      yield put(uiControlAction.clearUd0630Status({ sn }))
      yield put(
        uiControlAction.setUD0630Success({
          sn,
          key: 'refSource',
        })
      )

      yield delay(1000)
      yield put(modalActions.clearAllModal())
      yield put(uiControlAction.clearUd0630Status({ sn }))
    }

    // spec: 每次切 source 都要更新燈號
    currentData.current.ledIndicators = ledIndicators

    yield put(uiControlAction.setIsFreqDisabled({ sn, value: isFreqDisabled }))
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] setRefSource error:', error)
  }
}
export function* outputRefSource(response) {
  try {
    const [sn, { isEnable, currentSelection }] = Object.entries(
      response?.data
    )[0]

    let { currentData } = yield call(getCommonArgs, sn)
    currentData.settings.device.outputRefSource.isEnable = isEnable

    if (isEnable) currentData.current.ledIndicators.ref = 0
    if (!isEnable) currentData.current.ledIndicators.ref = null

    if (currentSelection)
      currentData.settings.device.outputRefSource.currentSelection =
        currentSelection

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(uiControlAction.clearUd0630Status({ sn }))
  } catch (error) {
    devWarLog('[res-handler] outputRefSource error:', error)
  }
}
export function* outputLOFrequency(response) {
  try {
    const [sn, { isOutputLoEnable }] = Object.entries(response?.data)[0]

    let { currentData } = yield call(getCommonArgs, sn)
    currentData.settings.device.isOutputLoEnable = isOutputLoEnable

    if (isOutputLoEnable) currentData.current.ledIndicators.LO = 0
    if (!isOutputLoEnable) currentData.current.ledIndicators.LO = null

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(uiControlAction.clearUd0630Status({ sn }))
  } catch (error) {
    devWarLog('[res-handler] outputLOFrequency error:', error)
  }
}
