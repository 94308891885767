import React from 'react'

import { h4, h6 } from 'src/containers/operating/device/freqConverter/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const LEDIndicators = props => {
  const { current } = useGetCurrentDeviceData()

  const { status, LO, ref } = current.data.current.ledIndicators

  const statusColor = { '-1': 'bg-red', 0: `bg-green` }
  const loColor = { '-1': `bg-red`, 0: `bg-green` }
  const refColor = { '-1': `bg-red`, 0: `bg-green`, 1: 'bg-orange' }

  let ledList = [
    {
      label: 'Status',
      background: statusColor[status],
      content: <></>,
    },
    { label: 'LO', background: loColor[LO], content: <></> },
    { label: 'Ref.', background: refColor[ref], content: <></> },
  ]

  return (
    <div className={container}>
      <h4 className={h4 + ' text-white'}>LED Indicators Display</h4>

      <div className={mainContainer}>
        {ledList.map((e, i) => (
          <div key={'LED Indicators ' + e.label + i} className={columnBox}>
            <h6 className={label}>{e.label}</h6>
            <div className={light + e.background}>{e.content}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LEDIndicators

const container = `[OtherSettings-LEDIndicators-container] flex flex-col gap-y-4 px-2`

const mainContainer = `[OtherSettings-LEDIndicators-mainContainer]
                        w-full 
                        flex 
                        justify-center 
                        items-end 
                        gap-x-6 
                        md:gap-x-4
                        `

const columnBox = `
                flex flex-col items-center gap-y-4`

const light = `
                w-4 h-4 
                rounded-full 
                border-1 
                border-solid 
                border-dark-5 
                duration-500 
                flex items-center justify-center 
                `

const label = `${h6} text-light-4 w-[40px] text-center`
