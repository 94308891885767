import React from 'react'
import { useFBX } from '@react-three/drei'

import BBoxOneFbx from 'src/assets/fbx/BBoxOne.fbx'
import BBoxLiteFbx from 'src/assets/fbx/BBoxLite.fbx'
import BBoardFbx from 'src/assets/fbx/BBoard.fbx'
import CloverCellArray1024Fbx from 'src/assets/fbx/CloverCell_Array1024.fbx'
import CloverCellEvb from 'src/assets/fbx/CloverCellEvb.fbx'
import Ris from 'src/assets/fbx/Ris.fbx'

import { degreeToRadian } from 'src/components/composite/beamformers/Beam3DControl/tools'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

const data = {
  bboard: {
    file: BBoardFbx,
    rotation: [0, degreeToRadian(180), 0],
    position: [0, -0.3, 0.2],
    scale: 1,
  },
  bboxone: {
    file: BBoxOneFbx,
    rotation: [0, 0, 0],
    position: [0, -0.4, 0],
    scale: 1,
  },
  bboxlite: {
    file: BBoxLiteFbx,
    rotation: [0, degreeToRadian(180), 0],
    position: [0, -0.3, 1.5],
    scale: 1,
  },
  cloverCellEvb: {
    file: CloverCellEvb,
    rotation: [degreeToRadian(180), degreeToRadian(90), 0],
    position: [0, -0.6, 0.5],
    scale: 0.1,
  },
  cloverCellArray1024: {
    file: CloverCellArray1024Fbx,
    rotation: [degreeToRadian(90), degreeToRadian(0), degreeToRadian(90)],
    position: [12.8, -7, -30.4],
    scale: 0.4,
  },
  ris: {
    file: Ris,
    rotation: [degreeToRadian(-90), 0, 0],
    position: [0, -1, 0],
    scale: 0.05,
  },
}

const Device = ({ deviceType }) => {
  const devName = getDeviceInfo[deviceType]?.devName
  const deviceDevName = devName?.first + devName?.second
  const args = data[deviceDevName]
  const fbx = useFBX(args?.file).clone()

  return (
    <group rotation={args.rotation} position={args.position} scale={args.scale}>
      <primitive object={fbx} />
    </group>
  )
}

export default Device
