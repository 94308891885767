import React from 'react'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { getUdChannelMode } from 'src/funcs/getDeviceInfo'

import { BiCheck } from 'react-icons/bi'

const LEDIndicators = props => {
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { PLO_LOCK, CH1, CH2, OUT_10M, LED_100M, PWR_5V, PWR_9V } =
    current.data.current.ledIndicators

  const isSingle = getUdChannelMode(sn) === 'single'

  const offBg = `bg-black/30 `

  const lockColor = +PLO_LOCK ? `bg-red ` : offBg
  const ch1Color = +CH1 ? `bg-white ` : offBg
  const ch2Color = +CH2 ? `bg-white ` : offBg
  const out10mColor = +OUT_10M ? `bg-white ` : offBg
  const led100mColor =
    +LED_100M === 1 ? `bg-white ` : +LED_100M === 2 ? `bg-blue ` : offBg
  const pwr5vColor = +PWR_5V ? `bg-white ` : offBg
  const pwr9vColor = +PWR_9V ? `bg-white ` : offBg

  let ledList = [
    {
      label: 'Lock',
      background: lockColor,
      content: <BiCheck className={+PLO_LOCK ? 'opacity-60' : 'hidden'} />,
    },
    { label: 'CH 1', background: ch1Color, content: <></> },
    { label: 'CH 2', background: ch2Color, content: <></> },
    { label: '10 MHz', background: out10mColor, content: <></> },
    { label: '100 MHz', background: led100mColor, content: <></> },
    { label: '5 V', background: pwr5vColor, content: <></> },
    { label: '9 V', background: pwr9vColor, content: <></> },
  ]

  // Single 有孔位但是沒有標文字，所以這邊只拔掉 label
  // 一樣套 ch2Color 是因為這樣才能 debug, 如果 single ch2 還亮燈，那一定哪裡有問題！
  if (isSingle)
    ledList = ledList.map(e =>
      e.label === 'CH 2'
        ? { label: '', background: ch2Color, content: <></> }
        : e
    )

  return (
    <div className={container}>
      <h4 className={h6 + ' text-white'}>LED Indicators Display</h4>
      <h6 className={h6 + ' text-light-4'}>
        Locked red light indicates that the device is in a normal state.
      </h6>

      <div className={mainContainer}>
        {ledList.map((e, i) => (
          <div key={'LED Indicators ' + e.label + i} className={columnBox}>
            <h6 className={label}>{e.label}</h6>
            <div className={light + e.background}>{e.content}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LEDIndicators

const container = `[OtherSettings-LEDIndicators-container] flex flex-col gap-y-2 px-2`

const mainContainer = `[OtherSettings-LEDIndicators-mainContainer]
                        w-full 
                        h-[74px] 
                        flex 
                        justify-center 
                        items-end 
                        gap-x-6 
                        md:gap-x-10
                        `

const columnBox = `
                flex flex-col gap-y-[6px]`

const light = `
                w-4 h-4 
                rounded-full 
                border-1 
                border-solid 
                border-dark-5 
                duration-500 
                flex items-center justify-center 
                `

const label = `${h6} text-light-4 w-4 translate-x-1 -rotate-45 whitespace-nowrap`
