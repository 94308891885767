import { call, put, delay } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { modalActions } from 'src/redux/slices/modal'
import { cclCttcUIControlForSingleActions as uiControlAction } from 'src/redux/slices/uiControl/beamformers/cloverCellCttc'

export function* common_refSignalSourceChange(response) {
  try {
    const [sn, { value }] = Object.entries(response?.data)[0]

    let currentData = yield call(getCurrentData, sn)
    currentData.deviceControl.common.refSource = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    const isToExternal = +value === 1

    // to external 的 modal 會顯示 loading
    // 這邊把它改成 success 等 1 秒後跑正常關掉流程
    if (isToExternal) {
      yield put(uiControlAction.setRefSourceIsSuccess({ sn }))
      yield delay(1000)
      yield put(uiControlAction.clearRefSourceStatus({ sn }))
    }

    yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog(error)
  }
}

export function* common_frequencyChange(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* common_externalControlChange(response) {
  try {
    yield console.log()
    // 這個 response 是 204
  } catch (error) {
    devWarLog(error)
  }
}
