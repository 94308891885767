import * as R from 'ramda'
import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import {
  setCameraArgs,
  setImportFileName,
  setShowImportModeMask,
} from 'src/redux/slices/uiControl/beamformers/ris'

import { devWarLog } from 'src/funcs/tools'
import { getBeamIndex, getBeamName } from 'src/funcs/device/ris'

function* _updateSteeringThetaPhiChange({ payloads }) {
  const { sn, targetBeam, theta, phi } = payloads
  let { currentData } = yield call(getCommonArgs, sn)

  if (targetBeam !== null) {
    const beamName = getBeamName(targetBeam)
    const beamIndex = getBeamIndex(targetBeam)

    currentData.deviceControl.steering[beamName][beamIndex].theta = theta
    currentData.deviceControl.steering[beamName][beamIndex].phi = phi

    yield put(setSingleDeviceData({ sn, data: currentData }))
  }

  return {
    incident: currentData.deviceControl.steering['incident'],
    reflection: currentData.deviceControl.steering['reflection'],
  }
}

function _changeValueTypeToNumber({ incident, reflection }) {
  const number_incident = R.map(e => ({
    ...e,
    theta: +e.theta,
    phi: +e.phi,
    distance: +e.distance,
  }))(incident)

  const number_reflection = R.map(e => ({
    ...e,
    theta: +e.theta,
    phi: +e.phi,
  }))(reflection)

  return { number_incident, number_reflection }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    // payloads format: { sn, targetBeam, theta, phi, isValid }
    const { sn, isValid } = payloads
    const { incident, reflection } = yield call(_updateSteeringThetaPhiChange, {
      payloads,
    })

    if (isValid) {
      let { lookupID } = yield call(getCommonArgs, sn)

      const { number_incident, number_reflection } = _changeValueTypeToNumber({
        incident,
        reflection,
      })

      yield call(__socket_API_sender, beamformersApi.RIS_STEERING_CHANGE_V0, {
        sn,
        lookupID,
        incident: number_incident,
        reflection: number_reflection,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steeringDistanceChange(payloads) {
  try {
    const { sn, value, index, isValid } = payloads

    let { currentData } = yield call(getCommonArgs, sn)

    // 如果是 import mode 點擊 ok 的話 value 會 === null
    // 這時就不用改值
    if (value !== null) {
      currentData.deviceControl.steering.incident[index].distance = value
      yield put(setSingleDeviceData({ sn, data: currentData }))
    }

    if (isValid) {
      let { currentData, lookupID } = yield call(getCommonArgs, sn)
      const incident = currentData.deviceControl.steering.incident
      const reflection = currentData.deviceControl.steering.reflection

      const { number_incident, number_reflection } = _changeValueTypeToNumber({
        incident,
        reflection,
      })

      yield call(__socket_API_sender, beamformersApi.RIS_STEERING_CHANGE_V0, {
        sn,
        lookupID,
        incident: number_incident,
        reflection: number_reflection,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringDistanceChange error:', error)
  }
}

export function* steeringImportModeMaskOkClick(payloads) {
  try {
    const { sn } = payloads

    yield put(setImportFileName({ sn, value: null }))
    yield put(setShowImportModeMask({ sn, value: false }))

    // call distance change 就會 call steering input change api
    // 然後 更新 channel 了
    yield call(steeringDistanceChange, {
      sn,
      value: null,
      index: null,
      isValid: true,
    })
  } catch (error) {
    devWarLog('[handler] steeringImportModeMaskOkClick error:', error)
  }
}

export function* steering3DCameraChange(action) {
  yield put(setCameraArgs(action.payload))
}
