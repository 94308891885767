import { createAction } from '@reduxjs/toolkit'

export const cloverCellCTTC_common_refSignalSourceChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_common_refSignalSourceChange_watcher'
)
export const cloverCellCTTC_common_frequencyChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_common_frequencyChange_watcher'
)
export const cloverCellCTTC_common_externalControlChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_common_externalControlChange_watcher'
)

export const cloverCellCTTC_steering_controlPatternChange_watcher =
  createAction(
    'deviceControl/cloverCellCTTC_steering_controlPatternChange_watcher'
  )
export const cloverCellCTTC_steering_beamSynthesisChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_steering_beamSynthesisChange_watcher'
)
export const cloverCellCTTC_steering_rfModeChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_steering_rfModeChange_watcher'
)
export const cloverCellCTTC_steering_powerChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_steering_powerChange_watcher'
)
export const cloverCellCTTC_steering_thetaPhiGainChange_watcher = createAction(
  'deviceControl/cloverCellCTTC_steering_thetaPhiGainChange_watcher'
)

//  modal event
export const cloverCellCTTC_toExternalModal_useExternalClick_watcher =
  createAction(
    'modalEvent/cloverCellCTTC_toExternalModal_useExternalClick_watcher'
  )
export const cloverCellCTTC_toExternalModal_cancelClick_watcher = createAction(
  'modalEvent/cloverCellCTTC_toExternalModal_cancelClick_watcher'
)
