import { call, put, take, race } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'
import { modalActions } from 'src/redux/slices/modal'
import {
  cloverCellCTTC_toExternalModal_cancelClick_watcher,
  cloverCellCTTC_toExternalModal_useExternalClick_watcher,
} from 'src/redux/actions/beamformers/cloverCellCTTCAction'
import { cclCttcUIControlForSingleActions as uiControlActions } from 'src/redux/slices/uiControl/beamformers/cloverCellCttc'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

export function* common_refSignalSourceChange(payloads) {
  try {
    const { sn, value, showCheckModal } = payloads

    let { lookupID } = yield call(getCommonArgs, sn)

    const isToExternal = +value === 1

    // 這邊是從 UI 上點擊 toExternal 時觸發
    if (isToExternal && showCheckModal) {
      yield put(modalActions.showModal_cclCTTC_toExternalCheck({ sn }))

      const { isCancelClicked, isToExternalClicked } = yield race({
        isCancelClicked: take(
          cloverCellCTTC_toExternalModal_cancelClick_watcher.type
        ),
        isToExternalClicked: take(
          cloverCellCTTC_toExternalModal_useExternalClick_watcher.type
        ),
      })

      if (isCancelClicked) yield put(modalActions.clearAllModal())

      if (isToExternalClicked) {
        yield put(uiControlActions.setRefSourceIsRequesting({ sn }))
        yield call(
          __socket_API_sender,
          beamformersApi.CLOVERCELL_CTTC_COMMON_SET_REF_SOURCE,
          { sn, lookupID, value: +value }
        )
      }
    }
    // 這邊是從 toInternal 或 toExternal failed 觸發
    else {
      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_CTTC_COMMON_SET_REF_SOURCE,
        { sn, lookupID, value: +value }
      )
    }
  } catch (error) {
    devWarLog('[handler] common_refSignalSourceChange error:', error)
  }
}

export function* common_frequencyChange(payloads) {
  try {
    const { sn, value } = payloads

    let { lookupID } = yield call(getCommonArgs, sn)

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_COMMON_FREQUENCY,
      { sn, lookupID, value: +value }
    )
  } catch (error) {
    devWarLog('[handler] common_frequencyChange error:', error)
  }
}

export function* common_externalControlChange(payloads) {
  try {
    const { sn } = payloads

    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    const new_isExternalControl =
      !currentData.deviceControl.common.isExternalControl
    currentData.deviceControl.common.isExternalControl = new_isExternalControl
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_COMMON_EXTERNAL_CONTROL_CHANGE,
      { sn, lookupID, value: +new_isExternalControl }
    )
  } catch (error) {
    devWarLog('[handler] common_externalControlChange error:', error)
  }
}
