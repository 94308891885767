import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import Ris0 from 'src/containers/operating/device/beamformers/ris/v0'
import Ris1 from 'src/containers/operating/device/beamformers/ris/v1'

import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'

import { beamformers_initDevice_watcher } from 'src/redux/actions/beamformers/generalAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

import operational_failure from 'src/assets/img/operational_failure.png'

import { v0_ris_hw_version, v1_ris_hw_version } from 'src/constants/beamformers'

const Ris = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const info = current.data.info
  const hwVersion = info?.versionInfo?.hardware
  const isV0 = v0_ris_hw_version.includes(hwVersion)
  const isV1 = v1_ris_hw_version.includes(hwVersion)

  const { isMobile, isSM } = useGetScreenSize()

  useEffect(() => {
    if (info?.initialization === 'unfinished')
      dispatch(beamformers_initDevice_watcher({ sn: sn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  //* mobile 不支援，所以直接 break
  if (isMobile || isSM) return <NotSupportMobile />

  if (info?.initialization === 'unfinished') return <DeviceInitializing />
  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(beamformers_initDevice_watcher({ sn }))}
      />
    )

  //* init 成功後，根據 hw version render 不同畫面
  if (isV0) return <Ris0 />
  if (isV1) return <Ris1 />

  return (
    <DeviceSomethingWereWrong
      handleRetry={() => dispatch(beamformers_initDevice_watcher({ sn }))}
    />
  )
}

const RisWithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <Ris />
    </ErrorBoundaryOfDevice>
  )
}

export default RisWithErrorBoundary

const NotSupportMobile = () => (
  <section className='w-full h-[515px] bg-light-4/10 flex flex-col justify-center items-center gap-y-10'>
    <div className='w-[240px] h-[188px]'>
      <img src={operational_failure} alt='TMYTEK operational_failure' />
    </div>

    <div className='w-[288px] text-light-4 font-normal text-base leading-5 text-center'>
      Mobile controls are not supported. Please use the desktop computer to
      continue.
    </div>
  </section>
)
