import React from 'react'
import { useDispatch } from 'react-redux'
import { container } from 'src/containers/operating/device/beamformers/style'
import CentralFrequency from 'src/containers/operating/device/beamformers/cloverCell/cttc/Common/CentralFrequency'
import RefSignalSource from 'src/containers/operating/device/beamformers/cloverCell/cttc/Common/RefSignalSource'

import { divider } from 'src/containers/operating/device/beamformers/style'
import CommonSwitch from 'src/components/Switch/CommonSwitch'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_common_externalControlChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const Common = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isExternalControl = current.data.deviceControl.common?.isExternalControl

  const handleExternalSwitch = () =>
    dispatch(cloverCellCTTC_common_externalControlChange_watcher({ sn }))

  return (
    <div className={container}>
      <div className='flex flex-col gap-y-4'>
        <div className='flex justify-between'>
          <div className='w-1/2'>
            <CentralFrequency />
          </div>

          <CommonSwitch
            type='text' // text , block , dot
            onText='External Control' // string
            offText='External Control' // string
            size='md' // sm, md
            on={isExternalControl} // bool
            onClick={handleExternalSwitch}
          />
        </div>

        <div className={divider} />

        <RefSignalSource />
      </div>
    </div>
  )
}

Common.propTypes = {}

export default Common
