import React from 'react'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const offBgColor = `bg-black/30 `

const LEDIndicators = props => {
  const { current } = useGetCurrentDeviceData()

  const { output10M, lock } = current.data.current.ledIndicators

  const output10MBg = output10M ? 'bg-white' : offBgColor
  const lockBg = lock ? 'bg-blue' : offBgColor

  let ledList = [
    {
      label: <>10 MHz Output</>,
      background: output10MBg,
    },
    {
      label: <>LO</>,
      background: lockBg,
    },
  ]

  return (
    <div className={container}>
      <h4 className={h6 + ' text-white'}>LED Indicators Display</h4>

      <div className={mainContainer}>
        {ledList.map((e, i) => (
          <div key={'sg LED Indicators ' + e.label + i} className={columnBox}>
            <h6 className={label}>{e.label}</h6>
            <div className={light + e.background} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LEDIndicators

const container = `[LEDIndicators-container] flex flex-col px-2`

const mainContainer = `[LEDIndicators-mainContainer]
                        w-full 
                        flex 
                        justify-center 
                        items-end 
                        mt-4 
                        gap-x-2 
                        `

const columnBox = `flex flex-col items-center gap-y-3 `

const light = `
                w-4 h-4 
                rounded-full 
                border-1 
                border-solid 
                border-dark-5 
                duration-500 
                `

const label = `${h6} w-[96px] text-light-4 text-center whitespace-nowrap`
