import { all, call, fork, take, throttle } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

// action
import {
  ris_steering_thetaPhiChange_watcher,
  ris_steering_3DCameraChange_watcher,
  ris_steering_importModeMaskOKClick_watcher,
  ris_common_import_watcher,
  ris_common_afterImport_watcher,
  ris_common_exportClick_watcher,
  ris_steering_distanceChange_watcher,
} from 'src/redux/actions/beamformers/risAction'

// handlers

import {
  steeringThetaPhiChange as handleSteeringThetaPhiChangeV0,
  steeringDistanceChange as handleSteeringDistanceChangeV0,
  steering3DCameraChange as handleSteering3DCameraChange,
  steeringImportModeMaskOkClick as handleSteeringImportModeMaskOkClickV0,
} from 'src/redux/sagas/handlers/event/beamformers/ris/v0/steering'
import {
  commonImport as handleCommonImportV0,
  commonAfterImport as handleCommonAfterImportV0,
  commonExport as handleCommonExportV0,
} from 'src/redux/sagas/handlers/event/beamformers/ris/v0/common'

import {
  steeringThetaPhiChange as handleSteeringThetaPhiChangeV1,
  steeringDistanceChange as handleSteeringDistanceChangeV1,
  steeringImportModeMaskOkClick as handleSteeringImportModeMaskOkClickV1,
} from 'src/redux/sagas/handlers/event/beamformers/ris/v1/steering'
import {
  commonImport as handleCommonImportV1,
  commonAfterImport as handleCommonAfterImportV1,
  commonExport as handleCommonExportV1,
} from 'src/redux/sagas/handlers/event/beamformers/ris/v1/common'

import { devLog } from 'src/funcs/tools'
import { v0_ris_hw_version, v1_ris_hw_version } from 'src/constants/beamformers'

const isV0 = hwVersion => v0_ris_hw_version.includes(hwVersion)
const isV1 = hwVersion => v1_ris_hw_version.includes(hwVersion)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* commonImport() {
  while (true) {
    const action = yield take(ris_common_import_watcher.type)
    devLog('[watcher] commonImport', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion)) yield fork(handleCommonImportV0, action.payload)
    if (isV1(hwVersion)) yield fork(handleCommonImportV1, action.payload)
  }
}
function* commonAfterImport() {
  while (true) {
    const action = yield take(ris_common_afterImport_watcher.type)
    devLog('[watcher] commonAfterImport', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion)) yield fork(handleCommonAfterImportV0, action.payload)
    if (isV1(hwVersion)) yield fork(handleCommonAfterImportV1, action.payload)
  }
}
function* commonExport() {
  while (true) {
    const action = yield take(ris_common_exportClick_watcher.type)
    devLog('[watcher] commonExport', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion)) yield fork(handleCommonExportV0, action.payload)
    if (isV1(hwVersion)) yield fork(handleCommonExportV1, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Beam Steering   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

function* steeringThetaPhiChange() {
  while (true) {
    const action = yield take(ris_steering_thetaPhiChange_watcher.type)
    devLog('[watcher] steeringThetaPhiChange', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion))
      yield fork(handleSteeringThetaPhiChangeV0, action.payload)
    if (isV1(hwVersion))
      yield fork(handleSteeringThetaPhiChangeV1, action.payload)
  }
}
function* steeringDistanceChange() {
  while (true) {
    const action = yield take(ris_steering_distanceChange_watcher.type)
    devLog('[watcher] steeringDistanceChange', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion))
      yield fork(handleSteeringDistanceChangeV0, action.payload)
    if (isV1(hwVersion))
      yield fork(handleSteeringDistanceChangeV1, action.payload)
  }
}
function* steeringImportModeMaskOkClick() {
  while (true) {
    const action = yield take(ris_steering_importModeMaskOKClick_watcher.type)
    devLog('[watcher] steeringImportModeMaskOkClick', action.payload)

    const { sn } = action.payload
    let { hwVersion } = yield call(getCommonArgs, sn)

    if (isV0(hwVersion))
      yield fork(handleSteeringImportModeMaskOkClickV0, action.payload)
    if (isV1(hwVersion))
      yield fork(handleSteeringImportModeMaskOkClickV1, action.payload)
  }
}

function* steering3DCameraChange() {
  yield throttle(
    1000,
    ris_steering_3DCameraChange_watcher,
    handleSteering3DCameraChange
  )
}

export default function* risWatchers() {
  yield all([
    steeringThetaPhiChange(),
    steeringDistanceChange(),
    steeringImportModeMaskOkClick(),
    steering3DCameraChange(),
    commonImport(),
    commonAfterImport(),
    commonExport(),
  ])
}
