import React from 'react'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const bgColor = { 0: 'bg-green ', 1: 'bg-yellow', 2: 'bg-red' }
const offBgColor = `bg-black/30 `

const LEDIndicators = props => {
  const { current } = useGetCurrentDeviceData()

  const { status, LO, ref } = current.data.current.ledIndicators

  const statusBg = bgColor?.[status] || offBgColor
  const refBg = bgColor?.[ref] || offBgColor
  const loBg = bgColor?.[LO] || offBgColor

  let ledList = [
    { label: 'Status', background: statusBg },
    { label: 'Ref.', background: refBg },
    { label: 'LO', background: loBg },
  ]

  return (
    <div className={container}>
      <h4 className={h6 + ' text-white'}>LED Indicators Display</h4>

      <div className={mainContainer}>
        {ledList.map((e, i) => (
          <div key={'LED Indicators ' + e.label + i} className={columnBox}>
            <h6 className={label}>{e.label}</h6>
            <div className={light + e.background} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LEDIndicators

const container = `[LEDIndicators-container] flex flex-col px-2`

const mainContainer = `[LEDIndicators-mainContainer]
                        w-full 
                        flex 
                        justify-center 
                        items-end 
                        mt-4 
                        gap-x-2 
                        md:gap-x-2
                        `

const columnBox = `flex flex-col items-center gap-y-3`

const light = `
                w-4 h-4 
                rounded-full 
                border-1 
                border-solid 
                border-dark-5 
                duration-500 
                `

const label = `${h6} text-light-4 w-[44px] text-center whitespace-nowrap`
