import React from 'react'
import { useDispatch } from 'react-redux'

import Distance from 'src/components/composite/InputGroupAppendDialog/Distance'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'
import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import {
  ris_steering_distanceChange_watcher,
  ris_steering_thetaPhiChange_watcher,
} from 'src/redux/actions/beamformers/risAction'

const BeamSteering = ({ freq, port }) => {
  const dispatch = useDispatch()
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn

  const i_theta =
    current?.data?.deviceControl[freq][port].steering.incident?.theta
  const i_thetaMax =
    current?.data?.deviceControl[freq][port].steering.incident?.thetaMax
  const i_phi = current?.data?.deviceControl[freq][port].steering.incident?.phi

  const r_theta =
    current?.data?.deviceControl[freq][port].steering.reflection?.theta
  const r_thetaMax =
    current?.data?.deviceControl[freq][port].steering.reflection?.thetaMax
  const r_phi =
    current?.data?.deviceControl[freq][port].steering.reflection?.phi

  const distance =
    current?.data?.deviceControl[freq][port].steering.incident?.distance
  const distanceMin =
    current?.data?.deviceControl[freq][port].steering.incident?.distanceMin
  const distanceMax =
    current?.data?.deviceControl[freq][port].steering.incident?.distanceMax

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleDistanceChange = ({ value, isValid }) =>
    dispatch(
      ris_steering_distanceChange_watcher({
        sn,
        freq,
        port,
        value,
        isValid,
      })
    )
  const handleInputChange = ({ label, targetBeam, value, isValid }) => {
    const prevTheta = targetBeam === 'incident' ? i_theta : r_theta
    const prevPhi = targetBeam === 'incident' ? i_phi : r_phi
    dispatch(
      ris_steering_thetaPhiChange_watcher({
        sn,
        freq,
        port,
        targetBeam,
        theta: label === 'theta' ? value : prevTheta,
        phi: label === 'phi' ? value : prevPhi,
        isValid,
      })
    )
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <>
      <h6 className={h6 + ' mb-1'}>Incident distance to RIS</h6>
      <Distance
        size='sm'
        value={distance}
        distanceMin={distanceMin}
        distanceMax={distanceMax}
        onChange={({ value, isValid }) =>
          handleDistanceChange({ value, isValid })
        }
      />

      <div className='flex gap-x-4 mt-2'>
        <div className='flex flex-col gap-y-2'>
          <h6 className={h6 + ' mt-2'}>Incident</h6>
          <Theta
            size='sm'
            value={i_theta}
            thetaMax={i_thetaMax}
            onChange={({ value, isValid }) =>
              handleInputChange({
                label: 'theta',
                targetBeam: 'incident',
                value,
                isValid,
              })
            }
          />
          <Phi
            size='sm'
            value={i_phi}
            onChange={({ value, isValid }) =>
              handleInputChange({
                label: 'phi',
                targetBeam: 'incident',
                value,
                isValid,
              })
            }
          />
        </div>

        <div className='flex flex-col gap-y-2'>
          <h6 className={h6 + ' mt-2'}>Reflection</h6>
          <Theta
            size='sm'
            value={r_theta}
            thetaMax={r_thetaMax}
            onChange={({ value, isValid }) =>
              handleInputChange({
                label: 'theta',
                targetBeam: 'reflection',
                value,
                isValid,
              })
            }
          />
          <Phi
            size='sm'
            value={r_phi}
            onChange={({ value, isValid }) =>
              handleInputChange({
                label: 'phi',
                targetBeam: 'reflection',
                value,
                isValid,
              })
            }
          />
        </div>
      </div>
    </>
  )
}

export default BeamSteering
