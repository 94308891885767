import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { HiOutlineSave, HiOutlineUpload } from 'react-icons/hi'

import CommonButton from 'src/components/Button/CommonButton'
import Tooltip from 'src/components/Popup/Tooltip'

import {
  ris_common_import_watcher,
  ris_common_exportClick_watcher,
} from 'src/redux/actions/beamformers/risAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
// import useUploadFiles from 'src/hooks/useUploadFiles'

import { modalActions } from 'src/redux/slices/modal'
// import { risCommonImportApi } from 'src/redux/sagas/services/restfulAPI/beamformersApi'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

//! 此參數可以開啟 export 功能，
//! 開發 import 時需要檔案可以打開 export 檔案
const _isSupportExport = false

const Common = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const { isDemoMode } = useGetCurrentMode()

  const inputFileRef = useRef()

  const freq = current?.data?.deviceControl?.common?.currentFreq
  const sn = current?.sn
  // const lookupID = current?.data?.deviceControl?.common?.lookupID

  const handleImportClick = () => inputFileRef.current.click()

  //! 2024/11/21 Ivy 臨時要給內部人用 import 量測
  //! 且 blob 傳法 BE 有問題沒時間解先在前端解析完傳普通 socket
  // const { handleFileInputChange } = useUploadFiles({
  //   inputFileRef,
  //   api: risCommonImportApi,
  //   payload: `{sn:${sn}},lookupID:${lookupID}`,
  //   beforeCallback: () => dispatch(modalActions.showModalOfFileImporting()),
  //   afterCallback: responseData =>
  //     dispatch(ris_common_afterImport_watcher({ sn, responseData })),
  // })

  const handleFileInputChange = async event => {
    try {
      if (event.target.files.length > 0) {
        const fileData = event.target.files[0]
        const fileName = fileData.name

        //* import flow:
        //* handle function -> 讀檔案整成 data
        //* -> spiImportConfig(saga) -> 判斷是否合法, 跳 modal
        //* -> callImportApi(saga) -> 判斷 call 哪隻 API / call API + 處理 response
        //! 在這邊開 modal 而不是 saga  開的原因是因為
        //! 如果 spi config 檔案太大 FileReader 會 read 很久 (尤其是 windows)
        //! 這樣使用者就會看到一段空窗期，所以在這邊就直接開 importing modal
        dispatch(modalActions.showModalOfFileImporting())

        const blob = new Blob([fileData], { type: fileData.type })
        const formData = new FormData()

        formData.append('file', blob, fileName)

        const { jsonFileData } = await readFileData(fileData)

        inputFileRef.current.value = await null

        await dispatch(
          ris_common_import_watcher({ sn, fileName, jsonFileData })
        )
      }
    } catch (error) {
      console.warn(error)
      dispatch(modalActions.showModalOfBBoxFailedToImportForIncorrectFormat())
    }
  }

  //! 2024/11/18 Ivy 說要把 export 拔掉 (rd2_app_ui)
  //! 怕之後要加回來，先用註解的方式
  //! 另外 redux 裡面的 watcher / handler / api 都還留著
  const handleExportClick = () =>
    dispatch(ris_common_exportClick_watcher({ sn }))

  return (
    <div className='flex justify-between items-center'>
      <h3 className='text-sm text-light-1'>Central Frequency: {freq} GHz</h3>

      <div className='flex items-center'>
        <Tooltip title='No support in demo mode' disabled={!isDemoMode}>
          <input
            className='hidden'
            ref={inputFileRef}
            type='file'
            accept='.csv'
            onChange={handleFileInputChange}
          />
          <CommonButton
            type='text'
            icon={<HiOutlineSave />}
            // disabled
            disabled={isDemoMode}
            onClick={handleImportClick}>
            Import
          </CommonButton>
        </Tooltip>

        {/* 2024/11/18 Ivy 說要把 export 拔掉 (rd2_app_ui)
        怕之後要加回來，先用註解的方式
        另外 redux 裡面的 watcher / handler / api 都還留著 */}
        {_isSupportExport && (
          <>
            <div className={divider} />

            <Tooltip
              title='No support in demo mode'
              // disabled={!isDemoMode}
            >
              <CommonButton
                type='text'
                icon={<HiOutlineUpload />}
                // disabled
                disabled={isDemoMode}
                onClick={handleExportClick}>
                Export
              </CommonButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}

export default Common

const divider = `h-6 w-[1px] bg-dark-5 mx-4`

const readFileData = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = e => {
      try {
        const fileContent = e.target.result
        const data = JSON.parse(fileContent)
        resolve({ jsonFileData: data })
      } catch (error) {
        reject(error) // 使用 reject 傳遞 JSON 解析錯誤
      }
    }

    reader.readAsText(file)
  })
