import Theta from 'src/assets/svg/unit/Theta'
import Phi from 'src/assets/svg/unit/Phi'
import Gain from 'src/assets/svg/unit/Gain'
import Phase from 'src/assets/svg/unit/Phase'
import { BiMoveHorizontal as DistanceIcon } from 'react-icons/bi'
import { IoResizeSharp } from 'react-icons/io5'
import { GrRotateRight, GrRotateLeft } from 'react-icons/gr'
import { MdDoNotDisturb } from 'react-icons/md'

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------                General               -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const cameraPosition = {
  close: [],
  mid: [16, 10, -16],
  far: [22, 16, -22],
}

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------              BBox - Antenna          -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const antennaNameTimestampFormat = `YYYYMMDDkkmmssSSZZ`

export const offsetMin = 0
export const offsetMax = 355

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------           BBox - Beam Steering       -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
// ----- input 規格
// 不設計成 object 或 class 是怕確認都沒確認就直接使用某個欄位會出事
// (thetaMax 會隨著天線而變動)
// (gainMin, gainMax 會隨著選 TX RX 而變動)
export const thetaIcon = <Theta />
export const thetaUnit = '˚'
export const thetaMin = 0
export const thetaStep = 1
export const thetaDecimalScale = 0

export const phiIcon = <Phi />
export const phiUnit = '˚'
export const phiMin = 0
export const phiMax = 359
export const phiStep = 1
export const phiDecimalScale = 0

export const gainIcon = <Gain />
export const gainStep = 0.5
export const gainUnit = 'dB'
export const gainDecimalScale = 1

export const phaseIcon = <Phase />
export const phaseUnit = '˚'
export const phaseMin = 0
export const phaseMax = 355
export const phaseStep = 5
export const phaseDecimalScale = 0

export const distanceIcon = <DistanceIcon className='text-light-4 text-xl' />
export const distanceUnit = 'm'
export const distanceStep = 0.01
export const distanceDecimalScale = 2

export const commonGainStep = 0.5

// ----- input 輸入非法值跳出的訊息
export const getCommonGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
    The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
    The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. 
  Each gain step is ${step}.`,
})

export const getThetaPhiPhaseWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
      The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
      The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Multiples of ${step} is required.`,
})

export const getGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min}. 
      The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
      The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Each gain step is ${step}.`,
})

export const getDistanceWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min}. 
      The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
      The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Each gain step is ${step}.`,
})

export const getCHGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
    Please adjust Common Gain for lower value.`,

  WARNING_MAX: `The maximum value is ${max}. 
    The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Each gain step is ${step}.`,
})

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------      BBox -  Beam Config Editor      -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const descriptionLengthMax = 50

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------              Clover Cell             -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const polarSynthesisIcon = {
  'Not using': <MdDoNotDisturb />,
  'Forward slash linear': <IoResizeSharp />,
  'Backward slash linear': <IoResizeSharp className='rotate-90' />,
  'Left-hand circular': <GrRotateRight />,
  'Right-hand circular': <GrRotateLeft />,
}

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------                  RIS                 -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
// --- 第一版(v0)
// --- 出貨時把 hw version 燒死為 '0.0.0.0' or '0.0.0.1'
// 0.0.0.0 = 28G
// 0.0.0.1 = 4.7G
export const v0_ris_hw_version = ['0.0.0.0', '0.0.0.1']
// --- 第二版(v1)
// --- 2024/12/20 臨時要出貨，且要支援 tile-up 所以 hw version 燒死為 '1'
// --- 且要支援特定臨時版 GUI
export const v1_ris_hw_version = ['0', '1']
