import React from 'react'
import { useDispatch } from 'react-redux'

import VerticalInputGroup from 'src/components/composite/beamformers/VerticalInputGroup'

import Attenuation from 'src/assets/svg/unit/Attenuation'
import Phase from 'src/assets/svg/unit/Phase'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  bboard_step_singlePowerSwitch_watcher,
  bboard_step_singleAttenuationChange_watcher,
  bboard_step_singlePhaseChange_watcher,
} from 'src/redux/actions/beamformers/bboardAction'

const SingleCH = ({ index }) => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common
  const { power, phase, attenuation } = current.data.deviceControl.step[rfMode]

  const { channelValue: lstPowerValues } = power
  const { channelValue: lstPhaseValues, valueMax: phaseMax } = phase
  const { channelValue: lstAttValues, valueMax: attMax } = attenuation

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const decidePlacement = index === 0 || index === 1 ? 'left' : 'right'

  // ---- attenuation (att)
  const attMin = 0
  const attStep = 1

  const attWarningText = {
    WARNING_MIN: `The minimum value is ${attMin}
      Please adjust Common attenuation for lower value.`,
    WARNING_MAX: `The maximum value is ${attMax}.
      The system will use the maximum value.`,
    WARNING_STEP: `Value not allowed. Each attenuation step is ${attStep}.`,
  }

  // ----- phase
  const phaseMin = 0
  const phaseStep = 1

  const phaseWarningText = {
    WARNING_MIN: `The minimum value is ${phaseMin}
      The system will use the minimum value.`,
    WARNING_MAX: `The maximum value is ${phaseMax}.
      The system will use the maximum value.`,
    WARNING_STEP: `Value not allowed. Multiples of ${phaseStep} is required.`,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handlePowerClick = () =>
    dispatch(
      bboard_step_singlePowerSwitch_watcher({
        sn,
        value: !lstPowerValues[index],
        channelIndex: index,
      })
    )

  const handleTopChange = ({ value, isValid }) =>
    dispatch(
      bboard_step_singleAttenuationChange_watcher({
        sn,
        channelIndex: index,
        value: value,
        isValid: isValid,
      })
    )

  const handleBottomChange = ({ value, isValid }) =>
    dispatch(
      bboard_step_singlePhaseChange_watcher({
        sn,
        channelIndex: index,
        value: value,
        isValid: isValid,
      })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const attInputProps = {
    // input params
    icon: <Attenuation />,
    unit: '',
    off: !lstPowerValues[index],
    step: attStep,
    validMin: 0,
    validMax: attMax,
    keydownMin: 0,
    keydownMax: attMax,
    decimalScale: 0,
    value: String(lstAttValues[index]),
    // progress bar params
    showProgress: false,
  }

  const phaseInputProps = {
    // input params
    icon: <Phase />,
    unit: '',
    off: !lstPowerValues[index],
    step: phaseStep,
    validMin: phaseMin,
    validMax: phaseMax,
    keydownMin: phaseMin,
    keydownMax: phaseMax,
    decimalScale: 0,
    value: String(lstPhaseValues[index]),
    // progress bar params
    showProgress: false,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <VerticalInputGroup
      chTitle={'CH ' + (index + 1)}
      isSwitchOn={lstPowerValues[index]}
      isSwitchDisabled={false}
      topTitle='Attenuation Step'
      topInputProps={attInputProps}
      topWarningText={attWarningText}
      topRangeText={`Range ${attMin} ~ ${attMax}`}
      bottomTitle='Phase Step'
      bottomInputProps={phaseInputProps}
      bottomWarningText={phaseWarningText}
      bottomRangeText={`Range ${phaseMin} ~ ${phaseMax}`}
      dialogPlacement={decidePlacement}
      {...{ handlePowerClick, handleTopChange, handleBottomChange }}
    />
  )
}

export default SingleCH
