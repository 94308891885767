import { delay, call, put } from 'redux-saga/effects'

import {
  getCommonArgs,
  getCurrentData,
} from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import __export_API_sender from 'src/redux/sagas/services/restfulTools/__export_API_sender'

import { risCommonExportChannelApi } from 'src/redux/sagas/services/restfulAPI/beamformersApi'
import { modalActions } from 'src/redux/slices/modal'
import {
  setImportFileName,
  setShowImportModeMask,
} from 'src/redux/slices/uiControl/beamformers/ris'
import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

export function* commonImport(payloads) {
  try {
    const { sn, freq, port, fileName, jsonFileData } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)

    // port ex: 'port_1
    // module = 1
    // v1 這版沒有 tile-up 所以直接裝 array 就好
    const module = [+port[port.length - 1]]

    yield call(__socket_API_sender, beamformersApi.RIS_PATTERN_IMPORT_V1, {
      sn,
      lookupID,
      freq,
      module,
      fileName,
      jsonFileData,
    })
  } catch (error) {
    devWarLog('[handler] commonImport error:', error)
  }
}

//! 臨時版 socket 用的
export function* commonAfterImport(response) {
  try {
    const [sn, { freq, module, channel, importFileName }] = Object.entries(
      response?.data
    )[0]

    // port ex: 'port_1
    // module = [1]
    // v1 這版沒有 tile-up 所以直接裝 array 就好
    const port = `port_${module[0]}`

    const newCurrentData = yield call(getCurrentData, sn)
    newCurrentData.deviceControl[freq][port].channel = channel

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
    yield put(setImportFileName({ sn, value: importFileName }))
    yield put(setShowImportModeMask({ sn, value: true }))

    yield put(modalActions.showModalOfFileImportSuccess())

    yield delay(1000)
    yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[handler] commonAfterImport error:', error)
  }
}

//! 正常版 blob 用的

// export function* commonAfterImport(response) {
//   try {
//     const { sn, responseData } = response
//     const { channel, importFileName } = responseData.data

//     const isSuccess = responseData.code === 0

//     if (isSuccess) {
//       let { currentData } = yield call(getCommonArgs, sn)
//       currentData.deviceControl.channel = channel
//       yield put(setSingleDeviceData({ sn, data: currentData }))

//       yield put(setImportFileName({ sn, value: importFileName }))
//       yield put(setShowImportModeMask({ sn, value: true }))

//       yield put(modalActions.showModalOfFileImportSuccess())

//       yield delay(1000)
//       yield put(modalActions.clearNormalModal())
//     }

//     if (!isSuccess) {
//       yield put(modalActions.showModalOfFileImportFailed())
//     }
//   } catch (error) {
//     devWarLog('[handler] commonAfterImport error:', error)
//   }
// }

export function* commonExport(payloads) {
  try {
    const { sn } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)
    yield call(__export_API_sender, {
      api: risCommonExportChannelApi,
      data: { sn, lookupID },
    })
  } catch (error) {
    devWarLog('[handler] commonExport error:', error)
  }
}
